<!-- @format -->

<template>
	<div>
		<div v-if="loading">Loading.....</div>
		<div>Getting Reminder Report please wait....</div>
	</div>
</template>
<script>
import { useQuery, useResult } from '@vue/apollo-composable'
import { onMounted, reactive } from '@vue/composition-api'
import Store from '@/store'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import GetReminderReportDataQuery from '@/_srcv2/pages/reminder/graphql/GetReminderReportDataQuery.graphql'
import useCalculateToPayFunctions from '@/_srcv2/pages/_reports/get-data-and-report/invoice/useCalculateToPayFunctions.js'
import useOcrNumber from '@/_srcv2/pages/_reports/create-report/useOcrNumber.js'

const { calculateToPay } = useCalculateToPayFunctions()
const { getOcrNumber, getSlicedOcrNumber } = useOcrNumber()

export default {
	name: 'GetReminderReportData',
	setup() {
		const {
			reportDocumentOurCompany,
			reportDocumentNumber,
			showGetData,
			showPdf,
			keyProcessReport,
			qr_nme,
			qr_cid,
			qr_iref,
			qr_idt,
			qr_ddt,
			qr_pt,
			qr_due,
			qr_acc,
			slicedOcrNumber,
			sendDocumentBy,
			hasValidEmail,
		} = useProcessReportStore()

		// ? get invoice report info
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result, refetch, loading } = useQuery(
			GetReminderReportDataQuery,
			() => ({
				company_nickname: reportDocumentOurCompany.value,
				reminder_id: reportDocumentNumber.value,
			}),
			options,
		)
		// ****************************************************************************************************
		const getReportData = (data) => {
			return new Promise((resolve, reject) => {
				if (data !== null || undefined) {
					const allReportDataForInvoice = useResult(
						result,
						null,
						(data) => data.document_transactions,
					)
					console.log(
						'33333333 >>>>>> allReportDataForInvoice',
						allReportDataForInvoice,
					)
					resolve(allReportDataForInvoice.value[0])
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo ---------------------------------------------------------------------------------------
		const validateEmail = (email) => {
			// eslint-disable-next-line no-useless-escape
			const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
			return regex.test(email)
		}
		const storeState = (data) => {
			const lines = data.document_transaction_goods_transactions_rel
			const results = calculateToPay(data, lines, 'reminder')
			const ocr = getOcrNumber(
				data.invoice_number,
				data.customer.customer_id,
				results.correctedTotal,
			)
			console.log('======== > ocr', ocr)
			const slicedOcr = getSlicedOcrNumber(ocr)
			console.log('======== > slicedOcr', slicedOcr)
			qr_nme.value = data.company.company_name
			qr_cid.value = data.customer.customer_id
			qr_iref.value = ocr
			qr_idt.value =
				data?.reminder_lines?._reminder_headers?.reminder_date.replaceAll(
					'-',
					'',
				)
			qr_ddt.value =
				data?.reminder_lines?._reminder_headers?.reminder_due_date.replaceAll(
					'-',
					'',
				)
			qr_due.value = results.correctedTotal
			qr_pt.value = 'BG'
			qr_acc.value = data.company.company_bank_giro
			slicedOcrNumber.value = slicedOcr
			sendDocumentBy.value = data.customer?.send_invoice_by
			hasValidEmail.value = validateEmail(data?.customer?.customer_email)
		}

		const storeReportData = (data) => {
			return new Promise((resolve, reject) => {
				if (data !== null || undefined) {
					storeState(data)
					Store.dispatch('setPdfReportData', data).then(() => {
						if (Store.getters.getPdfReportData !== undefined) {
							showGetData.value = false
							showPdf.value = true
							keyProcessReport.value += 1
							resolve(true)
						}
					})
				} else {
					const reason = new Error('Data can not be stored')
					reject(reason)
				}
			})
		}
		// ----------------------------------------------------------------------------
		const a = () => {
			getReportData(result).then((data) => {
				storeReportData(data)
			})
		}
		console.log('GetReminderReport created is fired')
		onMounted(() => {
			console.log('GetReminderReport onMounted is fired')
			refetch().then(() => a())
		})
		// todo ---------------------------------------------------------------
		return {
			loading,
		}
	},
}
</script>

<style scoped>
.margin-top-25 {
	margin-top: 25px;
}
</style>
