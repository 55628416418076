<!-- @format -->

<template>
	<transition name="modal-fade">
		<div>
			<div class="modal-backdrop">
				<div
					class="modal"
					role="dialog"
					aria-labelledby="modalTitle"
					aria-describedby="modalDescription"
				>
					<header class="modal-header" id="modalTitle">
						<slot name="header"></slot>
					</header>

					<section class="modal-body" id="modalDescription">
						<b-button
							type="button"
							class="is-danger"
							@click="onCloseModal"
							aria-label="Close modal"
							style="width: 250px"
						>
							X Close
						</b-button>
						<slot name="body"></slot>
						<div class="margin-top-10" v-if="!!showGetData">
							<GetReminderReportData :key="keyGetReportData" />
						</div>
						<div class="margin-top-10" v-if="!!showPdf">
							<process-report
								:key="keyProcessReport"
								:reportHeaders="reportHeaders"
							/>
						</div>
					</section>
					<footer class="modal-footer">
						<slot name="footer"></slot>
					</footer>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import ProcessReport from '@/_srcv2/pages/_reports/create-report/ProcessReport.vue'
import GetReminderReportData from './GetReminderReportData.vue'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import { onMounted, reactive } from '@vue/composition-api'

export default {
	name: 'ModalReminderFrame',
	components: { ProcessReport, GetReminderReportData },
	props: {
		refreshTable: Function,
	},
	setup(props) {
		const {
			reportDocumentNumber,
			keyProcessReport,
			keyGetReportData,
			isModalVisible,
			showPdf,
			showGetData,
		} = useProcessReportStore()
		const onCloseModal = () => {
			isModalVisible.value = false
			props.refreshTable()
		}
		const reportHeaders = reactive({
			reportType: 'reminder',
			reportNumber: reportDocumentNumber.value,
		})
		// --------------------------------------------------------------------------------
		onMounted(() => {
			showGetData.value = true
			showPdf.value = false
		})
		// ---------------------------------------------------------------------------
		return {
			onCloseModal,
			isModalVisible,
			reportHeaders,
			showPdf,
			showGetData,
			keyProcessReport,
			keyGetReportData,
		}
	},
}
</script>

<style scoped>
.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100000000001;
}
.modal {
	background-color: rgb(76, 92, 122);
	box-shadow: 2px 2px 20px 1px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	max-width: 780px;
	max-height: 100%;
	height: 800px;
	margin: auto;
	padding: 20px;
	opacity: 1;
}
.modal-header,
.modal-footer {
	padding: 15px;
	display: flex;
}
.modal-header {
	position: relative;
	color: #4aae9b;
	justify-content: space-between;
}
.modal-footer {
	flex-direction: column;
	justify-content: flex-end;
}
.modal-body {
	position: relative;
	padding: 20px 10px;
}
.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.5s ease;
}
</style>
