/** @format */

import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

const getOcrNumber = (invoiceNumber, customerId, toPay) => {
	// ** JavaScript implementation of the Luhn algorithm,
	// ** with calculation and validation functions
	// ? luhnChecksum
	// todo Implement the Luhn algorithm to calculate the Luhn check digit.
	// todo Return the check digit.
	const luhnChecksum = (code) => {
		var len = code.length
		var parity = len % 2
		var sum = 0
		for (var i = len - 1; i >= 0; i--) {
			var d = parseInt(code.charAt(i))
			if (i % 2 == parity) {
				d *= 2
			}
			if (d > 9) {
				d -= 9
			}
			sum += d
		}
		return sum % 10
	}
	// todo -------------------------------------------------------------------------
	// ? luhnCalculate
	// ** Return a full code (including check digit),
	// ** from the specified partial code
	// ** (without check digit).
	const luhnCalculate = (partCode) => {
		var checksum = luhnChecksum(partCode + '0')
		return checksum == 0 ? 0 : 10 - checksum
	}

	// ? luhnValidate
	// ** Return true if specified code (with check digit) is valid.

	const luhnValidate = (refNum) => {
		return luhnChecksum(refNum) == 0
	}
	// todo ***************************************************************************

	const firstSixDigit = invoiceNumber.slice(-6)
	const middleFourDigit = customerId.slice(-4)
	const lastSixDigit = ('00000' + Math.abs(toPay).toString()).slice(-6)
	const rawReferenceNumber = firstSixDigit + middleFourDigit + lastSixDigit
	console.log('rawReferenceNumber', rawReferenceNumber)
	console.log('rawReferenceNumber length', rawReferenceNumber.length)
	console.log('if **********', 21 === (1 || 11 || 21))
	const getLengthNumber = () => {
		const lengthRawReferenceNumber = rawReferenceNumber.length + 2
		switch (lengthRawReferenceNumber) {
			case 1:
			case 11:
			case 21:
				return 1
			case 2:
			case 12:
			case 22:
				return 2
			case 3:
			case 13:
			case 23:
				return 3
			case 4:
			case 14:
			case 24:
				return 4
			case 5:
			case 15:
			case 25:
				return 5
			case 6:
			case 16:
				return 6
			case 7:
			case 17:
				return 7
			case 8:
			case 18:
				return 8
			case 9:
			case 19:
				return 9
			case 0:
			case 10:
			case 20:
				return 0
			default:
			// code block
		}
	}
	const referenceNumber = rawReferenceNumber + getLengthNumber()
	console.log('Reference Number:', referenceNumber)
	const controlNumber = luhnCalculate(referenceNumber)
	console.log('Control Number:', controlNumber)
	const isValid = luhnValidate(controlNumber)
	console.log('isValid:', isValid)
	return referenceNumber + controlNumber
}

const getSlicedOcrNumber = (number) => {
	const firstPart = number.slice(0, 4)
	const secondPart = number.slice(4, 8)
	const thirdPart = number.slice(8, 12)
	const fourthPart = number.slice(12, 16)
	const fifthPart = number.slice(16, 18)
	return (
		firstPart +
		' ' +
		secondPart +
		' ' +
		thirdPart +
		' ' +
		fourthPart +
		' ' +
		fifthPart
	)
}

export default function useOcrNumber() {
	return {
		getOcrNumber,
		getSlicedOcrNumber,
	}
}
